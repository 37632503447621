<div [hidden]="!utilsService.showContent">


    <section id="welcome">

        <div class="welcome-video-container" *ngIf="introSlide?.showVideo && introSlide.videoURL">

            <video id="introvideo" autoplay loop muted playsinline>
                <source type="video/mp4" [src]="introSlide.videoURL">
            </video>

        </div>


        <div class="welcome-image-container">

            <carousel [interval]="6000" [isAnimated]="true" [showIndicators]="false" [noPause]="true">
                <slide *ngFor="let slide of welcomeSlides; let index=index">

                    <div [style.backgroundImage]="'url('+slide.image+')'" class="w-100 vh-100 background-size-cover"></div>

                </slide>
            </carousel>

        </div>


        <div class="container-fluid padding-top-60px">

            <div class="row target-row">

                <div class="col-md-5 offset-md-1 offset-lg-1 col-sm-12 my-auto typewriter-container">

                    <div [ngClass]="{'pb-5': !isMobile}">

                        <h1 class="text-center text-white" [ngClass]="{'font-size-30': isMobile}">
                            {{ 'PAGES.HOME.WELCOME_SECTION.TITLE' | translate }} <span class="text-white d-block" id="typewriterText"></span>
                        </h1>

                    </div>

                </div>

                <div class="col-md-6 col-sm-12 my-auto search-container">

                    <div class="row">

                        <div class="col-12">

                            <div class="card card-search-filters py-2 mx-auto" [ngClass]="isMobile ? 'w-100' : 'w-50'">

                                <div class="card-body">

                                    <div class="row">

                                        <div class="col-sm-12 col-md-12">

                                            <div class="form-group mb-0 text-center">

                                                <app-place-auto-complete [page]="'home'" [location]="''"></app-place-auto-complete>

                                            </div>

                                        </div>


                                        <div class="col-sm-12 col-md-12 mt-3">

                                            <app-guests-dropdown-filter [adults]="filters.adults" [children]="filters.children" [infants]="filters.infants"
                                                [page]="'home'">
                                            </app-guests-dropdown-filter>

                                        </div>


                                        <div class="col-sm-12 col-md-12 mt-3">

                                            <div class="form-group mb-0">

                                                <input type="text" class="form-control" id="dateRangepicker" name="dateRangepicker"
                                                    placeholder="{{'GENERIC.INPUTS.DATE.PLACEHOLDER' | translate }}" bsDaterangepicker class="form-control"
                                                    placement="bottom" [(ngModel)]="date_range"
                                                    [bsConfig]="{ rangeInputFormat: 'DD MMM YYYY', adaptivePosition: true, showWeekNumbers: false, containerClass: 'theme-orange', customTodayClass: 'bg-info' }"
                                                    [minDate]="datepickerMinDate" (ngModelChange)="onDaterangeChange()" #dateRangepicker="ngModel" required
                                                    readonly />

                                            </div>

                                        </div>


                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-12 mt-3">

                                            <div class="form-group mb-0">

                                                <button type="button" (click)="submitForm()" class="btn btn-tourmie-primary w-100" id="searchButton" [gaEvent]="{
                                                title: 'click_search',
                                                data:{
                                                    page: 'home',
                                                    persons: (filters?.adults || 0) + (filters?.children || 0) + (filters?.infants || 0),
                                                    start_date: filters?.daterange?.start,
                                                    end_date: filters?.daterange?.end,
                                                    location: filters?.place_name || null,
                                                    country: filters?.address_details?.country || null,
                                                    city: filters?.address_details?.city || null,
                                                    place_id: filters?.address_details?.google_place_id || null,
                                                    latitude: filters?.latitude || null,
                                                    longitude: filters?.longitude || null
                                                }
                                            }">
                                                    {{ 'GENERIC.BUTTONS.SEARCH' | translate }}
                                                </button>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </section>


    <section id="target-group">




        <div class="container-fluid pt-7">

            <div class="row" [ngClass]="{'px-4': isMobile}">

                <div class="col-12 text-center pt-3 pb-5">
                    <h2 class="mb-2 font-weight-600"> {{ 'PAGES.HOME.TARGET_GROUP_SECTION.TITLE' | translate }} </h2>
                    <p class="text-tourmie-darkblue-2 font-size-18"> {{ 'PAGES.HOME.TARGET_GROUP_SECTION.DESCRIPTION' | translate }}
                    </p>
                </div>

            </div>

            <div class="row px-4 justify-content-center" [ngClass]="{'px-4': isMobile}">


                <!-- categories: nomads -->
                <div class="col-sm-12 col-md-6 col-lg-2 col-target-group" *ngIf="utilsService.customDomainSettings?.visible_elements?.home_categories_nomads">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'digital_nomads' }"
                        [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'digital_nomads' } }">

                        <img src="assets/img/target-categories/digital_nomads.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_NOMADS_TITLE' | translate }}">

                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'digital_nomads' }"
                            [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'digital_nomads' } }">

                            <h5 class="text-center mt-2 font-weight-600">
                                {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_NOMADS_TITLE' | translate }}
                            </h5>

                        </a>



                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_NOMADS_DESCRIPTION' | translate }}
                        </p>

                    </div>

                </div>


                <!-- categories: professionals -->
                <div class="col-sm-12 col-md-6 col-lg-2  col-target-group"
                    *ngIf="utilsService.customDomainSettings?.visible_elements?.home_categories_professionals">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'professionals' }"
                        [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'professionals' } }">

                        <img src="assets/img/target-categories/professionals.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_PROFESSIONALS_TITLE' | translate }}">

                    </a>



                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'professionals' }"
                            [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'professionals' } }">

                            <h5 class="text-center mt-2 font-weight-600">
                                {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_PROFESSIONALS_TITLE' | translate }}
                            </h5>

                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_PROFESSIONALS_DESCRIPTION' | translate }}
                        </p>

                    </div>

                </div>


                <!-- categories: students -->
                <div class="col-sm-12 col-md-6 col-lg-2  col-target-group"
                    *ngIf="utilsService.customDomainSettings?.visible_elements?.home_categories_students">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'students' }"
                        [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'students' } }">

                        <img src="assets/img/target-categories/students.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_STUDENTS_TITLE' | translate }}">

                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'students' }"
                            [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'students' } }">

                            <h5 class="text-center mt-2 font-weight-600">
                                {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_STUDENTS_TITLE' | translate }}
                            </h5>

                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_STUDENTS_DESCRIPTION' | translate }}
                        </p>

                    </div>

                </div>


                <!-- categories: executives -->
                <div class="col-sm-12 col-md-6 col-lg-2  col-target-group"
                    *ngIf="utilsService.customDomainSettings?.visible_elements?.home_categories_executives">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'executives' }"
                        [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'executives' } }">

                        <img src="assets/img/target-categories/executives.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_EXECUTIVES_TITLE' | translate }}">

                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'executives' }"
                            [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'executives' } }">

                            <h5 class="text-center mt-2 font-weight-600">
                                {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_EXECUTIVES_TITLE' | translate }}
                            </h5>

                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_EXECUTIVES_DESCRIPTION' | translate }}
                        </p>

                    </div>

                </div>


                <!-- categories: families -->
                <div class="col-sm-12 col-md-6 col-lg-2 col-target-group" [ngClass]="isMobile ? 'mx-auto' : ''"
                    *ngIf="utilsService.customDomainSettings?.visible_elements?.home_categories_families">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'families' }"
                        [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'families' } }">

                        <img src="assets/img/target-categories/families.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_FAMILIES_TITLE' | translate }}">

                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ cgrouptypes: 'families' }"
                            [gaEvent]="{ title: 'click_target_group', data: { page: 'home', item: 'families' } }">

                            <h5 class="text-center mt-2 font-weight-600">
                                {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_FAMILIES_TITLE' | translate }}
                            </h5>

                        </a>

                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.TARGET_GROUP_SECTION.GROUP_FAMILIES_DESCRIPTION' | translate }}
                        </p>

                    </div>

                </div>

            </div>

        </div>

    </section>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path fill="#ffffff" fill-opacity="1"
            d="M0,160L48,170.7C96,181,192,203,288,181.3C384,160,480,96,576,85.3C672,75,768,117,864,122.7C960,128,1056,96,1152,101.3C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>


    <section id="discover">

        <div class="container-fluid">

            <div class="row" [ngClass]="{'px-4': isMobile}">

                <div class="col-12 text-center pb-5" [ngClass]="{'pt-5': isMobile}">
                    <h2 class="mb-2 font-weight-600"> {{ 'PAGES.HOME.DISCOVER_SECTION.TITLE' | translate }}
                    </h2>
                    <p class="text-tourmie-darkblue-2 font-size-18"> {{ 'PAGES.HOME.DISCOVER_SECTION.DESCRIPTION' | translate }}</p>
                </div>

            </div>

            <div class="row px-8" [ngClass]="{'px-4': isMobile}">

                <div class="col-sm-12 col-md-3" [ngClass]="{'px-4': !isMobile}">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'apartment' }"
                        [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'apartment' } }">
                        <img src="assets/img/stay-categories/apartment.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_1' | translate }}">
                    </a>

                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'apartment' }"
                            [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'apartment' } }">
                            <h5 class="text-center mt-2 font-weight-600">
                                {{'PAGES.HOME.DISCOVER_SECTION.TYPE_1' | translate }}
                            </h5>
                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_1_DESC' | translate }}
                        </p>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3" [ngClass]="{'px-4': !isMobile}">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'studio' }"
                        [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'studio' } }">
                        <img src="assets/img/stay-categories/studio.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_2' | translate }}">
                    </a>

                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'studio' }"
                            [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'studio' } }">
                            <h5 class="text-center mt-2 font-weight-600">
                                {{'PAGES.HOME.DISCOVER_SECTION.TYPE_2' | translate }}
                            </h5>
                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_2_DESC' | translate }}
                        </p>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3" [ngClass]="{'px-4': !isMobile}">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'house' }"
                        [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'house' } }">
                        <img src="assets/img/stay-categories/residence.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_3' | translate }}">
                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'house' }"
                            [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'house' } }">
                            <h5 class="text-center mt-2 font-weight-600">
                                {{'PAGES.HOME.DISCOVER_SECTION.TYPE_3' | translate }}
                            </h5>
                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_3_DESC' | translate }}
                        </p>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3" [ngClass]="{'px-4': !isMobile}">

                    <a [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'villa' }"
                        [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'villa' } }">
                        <img src="assets/img/stay-categories/villa.webp" class="d-block w-100 img-radius" loading="lazy"
                            alt="{{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_4' | translate }}">
                    </a>


                    <div class="py-3">

                        <a class="text-body" [routerLink]="[ '/properties' ]" [queryParams]="{ proptypes: 'villa' }"
                            [gaEvent]="{ title: 'click_property_group', data: { page: 'home', item: 'villa' } }">
                            <h5 class="text-center mt-2 font-weight-600">
                                {{'PAGES.HOME.DISCOVER_SECTION.TYPE_4' | translate }}
                            </h5>
                        </a>


                        <p class="text-tourmie-darkblue-2 text-center font-size-15">
                            {{ 'PAGES.HOME.DISCOVER_SECTION.TYPE_4_DESC' | translate }}
                        </p>
                    </div>
                </div>

            </div>

        </div>

    </section>


    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path fill="#ffffff" fill-opacity="1"
            d="M0,160L48,170.7C96,181,192,203,288,181.3C384,160,480,96,576,85.3C672,75,768,117,864,122.7C960,128,1056,96,1152,101.3C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
    </svg>

    <section id="tourmie-stay-why">

        <div class="container-fluid">


            <!-- title -->
            <div class="row" [ngClass]="{'px-4': isMobile}">

                <div class="col-12 text-center pb-5" [ngClass]="{'pt-5': isMobile}">
                    <h2 class="mb-2 font-weight-600"> {{ 'PAGES.HOME.WHY_SECTION.TITLE' | translate | replaceTourmieStay:
                        utilsService?.customDomainSettings?.app_title || 'Tourmie Stay'}} </h2>
                    <p class="text-tourmie-darkblue-2 font-size-18"> {{ 'PAGES.HOME.WHY_SECTION.DESCRIPTION' | translate | replaceTourmieStay:
                        utilsService.customDomainSettings.app_title}}</p>
                </div>

            </div>


            <!-- cycles for why rent section -->
            <div class="row" [ngClass]="{'px-4': isMobile}">

                <!-- fully furnished -->
                <div class="col-sm-12 col-why-section offset-md-1 text-center" [ngClass]="{
                        'mb-5': isMobile,
                        'col-md-2': utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'col-md-3 ml-0': !utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'circle-white-icon': utilsService.customDomainSettings?.visible_elements?.circles_white_icon
                    }">
                    <div class="icon-size-150 rounded-circle bg-tourmie-primary-light-5 mx-auto d-flex justify-content-center mb-3">
                        <img src="assets/img/whyStay/furnitures.png" width="75" height="75" class="my-auto" loading="lazy" alt="Furniture">
                    </div>
                    <h5 class="text-tourmie-darkblue-2 mt-4">{{ 'PAGES.HOME.WHY_SECTION.TYPE_1' | translate }}</h5>
                </div>


                <!-- high-speed wifi -->
                <div class="col-sm-12 col-why-section text-center" [ngClass]="{
                        'mb-5': isMobile,
                        'col-md-2': utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'col-md-3': !utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'circle-white-icon': utilsService.customDomainSettings?.visible_elements?.circles_white_icon
                    }">
                    <div class="icon-size-150 rounded-circle bg-tourmie-primary-light-5 mx-auto d-flex justify-content-center mb-3">
                        <img src="assets/img/whyStay/wifi.png" width="75" height="75" class="my-auto" loading="lazy" alt="WIFI">
                    </div>
                    <h5 class="text-tourmie-darkblue-2 mt-4">{{ 'PAGES.HOME.WHY_SECTION.TYPE_2' | translate }}</h5>
                </div>

                <!-- online payments -->
                <div class="col-sm-12 col-why-section text-center" [ngClass]="{
                        'mb-5': isMobile,
                        'col-md-2': utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'col-md-3': !utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'circle-white-icon': utilsService.customDomainSettings?.visible_elements?.circles_white_icon
                    }">
                    <div class="icon-size-150 rounded-circle bg-tourmie-primary-light-5 mx-auto d-flex justify-content-center mb-3">
                        <img src="assets/img/whyStay/credit-cards-payment.png" width="75" height="75" class="my-auto" loading="lazy" alt="Online payment">
                    </div>
                    <h5 class="text-tourmie-darkblue-2 mt-4">{{ 'PAGES.HOME.WHY_SECTION.TYPE_3' | translate }}</h5>
                </div>


                <!-- no hidden extra fees -->
                <div class="col-sm-12 col-why-section text-center" [ngClass]="{
                        'mb-5': isMobile,
                        'col-md-2': utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'col-md-3': !utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly,
                        'circle-white-icon': utilsService.customDomainSettings?.visible_elements?.circles_white_icon
                    }">
                    <div class="icon-size-150 rounded-circle bg-tourmie-primary-light-5 mx-auto d-flex justify-content-center mb-3">
                        <img src="assets/img/whyStay/security.png" width="75" height="75" class="my-auto" loading="lazy" alt="Secure payments">
                    </div>
                    <h5 class="text-tourmie-darkblue-2 mt-4">{{ 'PAGES.HOME.WHY_SECTION.TYPE_4' | translate }}</h5>

                </div>


                <!-- digital nomads friendly -->
                <div class="col-sm-12 col-md-2 col-why-section text-center"
                    [ngClass]="{'mb-5': isMobile, 'circle-white-icon': utilsService.customDomainSettings?.visible_elements?.circles_white_icon}"
                    *ngIf="utilsService.customDomainSettings?.visible_elements?.why_rent_with_tourmie_stay_section__digital_nomads_friendly">
                    <div class="icon-size-150 rounded-circle bg-tourmie-primary-light-5 mx-auto d-flex justify-content-center mb-3">
                        <img src="assets/img/whyStay/work-space.png" width="75" height="75" class="my-auto" loading="lazy" alt="Furniture">
                    </div>
                    <h5 class="text-tourmie-darkblue-2 mt-4">{{ 'PAGES.HOME.WHY_SECTION.TYPE_5' | translate }}</h5>
                </div>

            </div>

        </div>

    </section>


    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path fill="#ffffff" fill-opacity="1"
            d="M0,160L48,170.7C96,181,192,203,288,181.3C384,160,480,96,576,85.3C672,75,768,117,864,122.7C960,128,1056,96,1152,101.3C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>


    <section id="how-it-works">

        <div class="container">

            <div class="row" [ngClass]="{'px-4': isMobile}">

                <div class="col-12 text-center pb-5" [ngClass]="{'pt-5': isMobile}">
                    <h2 class="mb-2 font-weight-600"> {{ 'PAGES.HOME.HOW_SECTION.TITLE' | translate }} </h2>
                    <p class="text-tourmie-darkblue-2 font-size-18"> {{ 'PAGES.HOME.HOW_SECTION.DESCRIPTION' | translate }}</p>
                </div>

            </div>


            <!-- TILE 1 -->
            <div class="row mb-5" [ngClass]="{'px-4': isMobile}">


                <div class="col-sm-12 col-md-6" [ngClass]="{'p-2': !isMobile}">
                    <img [src]="utilsService?.customDomainSettings?.images?.home?.section_how_it_works?.item_1 || 'assets/img/home-how-it-works/how-it-works-1.webp'"
                        class="w-100 img-radius shadow height-auto" loading="lazy" alt="{{ 'PAGES.HOME.HOW_SECTION.TILE_1_TITLE' | translate }}">
                </div>


                <div class="col-sm-12 col-md-6 d-flex">

                    <div class="mx-auto" [ngClass]="{'w-75 my-auto': !isMobile, 'mt-4 text-center': isMobile}">
                        <h4 class="mb-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_1_TITLE' | translate }} </h4>
                        <p class="text-tourmie-darkblue-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_1_DESC' | translate }}</p>
                    </div>

                </div>


            </div>


            <!-- TILE 2 -->
            <div class="row mb-5" [ngClass]="{'px-4': isMobile}">


                <div class="col-sm-12 col-md-6 d-flex" [ngClass]="{'order-12': isMobile}">
                    <div class="mx-auto" [ngClass]="{'w-75 my-auto': !isMobile, 'mt-4 text-center': isMobile}">
                        <h4 class="mb-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_2_TITLE' | translate }} </h4>
                        <p class="text-tourmie-darkblue-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_2_DESC' | translate }}</p>
                    </div>
                </div>


                <div class="col-sm-12 col-md-6" [ngClass]="{'p-2': !isMobile, 'order-1': isMobile}">
                    <img [src]="utilsService?.customDomainSettings?.images?.home?.section_how_it_works?.item_2 || 'assets/img/home-how-it-works/how-it-works-2.webp'"
                        class="w-100 img-radius shadow height-auto" loading="lazy" alt="{{ 'PAGES.HOME.HOW_SECTION.TILE_2_TITLE' | translate }}">
                </div>


            </div>


            <!-- TILE 3 -->
            <div class="row mb-5" [ngClass]="{'px-4': isMobile}">


                <div class="col-sm-12 col-md-6" [ngClass]="{'p-2': !isMobile, 'order-1': isMobile}">
                    <img [src]="utilsService?.customDomainSettings?.images?.home?.section_how_it_works?.item_3 || 'assets/img/home-how-it-works/how-it-works-3.webp'"
                        class="w-100 img-radius shadow height-auto" loading="lazy" alt="{{ 'PAGES.HOME.HOW_SECTION.TILE_3_TITLE' | translate }}">
                </div>


                <div class="col-sm-12 col-md-6 d-flex" [ngClass]="{'order-12': isMobile}">
                    <div class="mx-auto" [ngClass]="{'w-75 my-auto': !isMobile, 'mt-4 text-center': isMobile}">
                        <h4 class="mb-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_3_TITLE' | translate }} </h4>
                        <p class="text-tourmie-darkblue-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_3_DESC' | translate }}</p>
                    </div>
                </div>


            </div>

            <!-- TILE 4 -->
            <div class="row mb-5" [ngClass]="{'px-4': isMobile}">


                <div class="col-sm-12 col-md-6 d-flex" [ngClass]="{'order-12': isMobile}">
                    <div class="mx-auto" [ngClass]="{'w-75 my-auto': !isMobile, 'mt-4 text-center': isMobile}">
                        <h4 class="mb-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_4_TITLE' | translate }} </h4>
                        <p class="text-tourmie-darkblue-2"> {{ 'PAGES.HOME.HOW_SECTION.TILE_4_DESC' | translate }}</p>
                    </div>
                </div>


                <div class="col-sm-12 col-md-6" [ngClass]="{'p-2': !isMobile, 'order-1': isMobile}">
                    <img [src]="utilsService?.customDomainSettings?.images?.home?.section_how_it_works?.item_4 || 'assets/img/home-how-it-works/how-it-works-4.webp'"
                        class="w-100 img-radius shadow height-auto" loading="lazy" alt="{{ 'PAGES.HOME.HOW_SECTION.TILE_4_TITLE' | translate }}">
                </div>


            </div>


        </div>

    </section>


    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
        <path fill="#ffffff" fill-opacity="1"
            d="M0,160L48,170.7C96,181,192,203,288,181.3C384,160,480,96,576,85.3C672,75,768,117,864,122.7C960,128,1056,96,1152,101.3C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
    </svg>

    <section id="end-cta" class="pb-8">

        <div class="container">

            <div class="row mb-2" [ngClass]="{'px-4 pt-5': isMobile}">
                <div class="col-12 text-center">
                    <h2 class="mb-2 font-weight-600"> {{ 'PAGES.HOME.END_CTA_SECTION.TITLE' | translate }}
                    </h2>
                    <p class="text-tourmie-primary">
                        <span *ngIf="utilsService?.customDomainSettings?.accommodation?.min_days === 15">
                            {{ 'PAGES.HOME.END_CTA_SECTION.DESCRIPTION_15' | translate }}
                        </span>
                        <span *ngIf="utilsService?.customDomainSettings?.accommodation?.min_days === 14">
                            {{ 'PAGES.HOME.END_CTA_SECTION.DESCRIPTION_14' | translate }}
                        </span>
                        <span *ngIf="utilsService?.customDomainSettings?.accommodation?.min_days === 30">
                            {{ 'PAGES.HOME.END_CTA_SECTION.DESCRIPTION' | translate }}
                        </span>
                    </p>
                </div>
            </div>

            <div class="row" [ngClass]="{'px-4': isMobile}">
                <div class="col-12 text-center">
                    <a class="btn btn-tourmie-primary rounded-pill px-3" [routerLink]="['/registration/guests']"
                        [gaEvent]="{ title: 'click_signup_cta_tenant', data: { page: 'home' } }">
                        {{'GENERIC.BUTTONS.SIGN_UP_FREE' | translate }}
                    </a>
                </div>
            </div>

        </div>

    </section>


    <section *ngIf="false" id="destinations" class="px-3">

        <div class="container">

            <div class="album py-5 bg-transparent">

                <h2 class="text-center py-3">Popular Destinations</h2>

                <div class="row">


                    <ng-container *ngIf="popularDestinations.length; else loadingPropsTemplate">


                        <!-- <div class="col-md-3" *ngFor="let item of popularDestinations; let i = index;">


            <a class="card mb-4 popDestCard" [routerLink]="['/properties']"
              [queryParams]="{location: item.city, place: item.google_place_id, latitude: item.latitude, longitude: item.longitude }">


              <img src='../assets/img/destinations/{{i+1}}.png' class="rounded-top"
                alt="{{popularDestinations[i].city}}" width="100%" height="40%" title="{{popularDestinations[i].city}}">


              <div class="card-body">


                <div class="d-flex justify-content-between align-items-center">

                  <h4 class="text-body">
                    <span class="badge badge-tourmie-secondary">{{popularDestinations[i].city}}</span>
                  </h4>

                  <span class="h6 text-muted">{{popularDestinations[i].total_properties}} Stays</span>

                </div>


              </div>

            </a>


          </div> -->


                        <carousel [itemsPerSlide]="carouselSettings.itemsPerSlide" [singleSlideOffset]="carouselSettings.singleSlideOffset" [startFromIndex]="6"
                            [interval]="false">
                            <slide *ngFor="let item of popularDestinations; let i = index;">
                                <div class="col-md-3" *ngFor="let item of popularDestinations; let i = index;">


                                    <a class="card mb-4 popDestCard" [routerLink]="['/properties']"
                                        [queryParams]="{location: item.city, place: item.google_place_id, latitude: item.latitude, longitude: item.longitude }">


                                        <img src='../assets/img/destinations/{{i+1}}.png' class="rounded-top" loading="lazy"
                                            alt="{{popularDestinations[i].city}}" width="100%" height="40%" title="{{popularDestinations[i].city}}">


                                        <div class="card-body">


                                            <div class="d-flex justify-content-between align-items-center">

                                                <h4 class="text-body">
                                                    <span class="badge badge-tourmie-secondary">{{popularDestinations[i].city}}</span>
                                                </h4>

                                                <span class="h6 text-muted">{{popularDestinations[i].total_properties}} Stays</span>

                                            </div>


                                        </div>

                                    </a>


                                </div>
                            </slide>
                        </carousel>

                    </ng-container>

                    <ng-template #loadingPropsTemplate>

                        <div class="col-md-3" *ngFor="let item of 4 | enumerate">

                            <div class="card mb-4 popDestCard">

                                <ngx-skeleton-loader [theme]="{  height: '160px' }">
                                </ngx-skeleton-loader>

                                <div class="card-body">

                                    <ngx-skeleton-loader [theme]="{ height: '20px' }">
                                    </ngx-skeleton-loader>

                                </div>

                            </div>

                        </div>


                    </ng-template>


                </div>

            </div>


        </div>

    </section>

</div>